import React, { useEffect, useState } from "react";
import Dash from "../Assets/dash.png";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Spin, Table } from "antd";
import TagChip from "../Components/TagChips";
import DriverIcon from "../Assets/driverIcon.png";
import { FaCar, FaRegCheckCircle } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { apiService, ROUTES } from "../Services/apiService";
import { dashboard } from "../Mock/responses";
// import { Modal } from "antd";
import { Box, Modal } from "@mui/joy";

function FitBounds({ locations }) {
  const map = useMap();
  console.log(locations);
  if (locations.length > 0) {
    const bounds = L.latLngBounds(
      locations.map((loc) => [loc.lat, loc.long])
    );
    map.fitBounds(bounds);
  }

  return null; // This component doesn't render anything
}

function AllActiveFitBounds({ locations }) {
  const map = useMap();
  console.log(locations);
  if (locations.length > 0) {
    const bounds = L.latLngBounds(
      locations.map((loc) => [loc.location.lat, loc.location.lon])
    );
    map.fitBounds(bounds);
  }

  return null; // This component doesn't render anything
}

const recentRidesTable = [
  {
    title: "Sno",
    dataIndex: "driver_details",
    align: "center",
    width: "10%",
    render: (_, { driver_details }, index) => (
      <>
        <p>{index + 1}</p>
      </>
    ),
  },
  {
    title: "User Name",
    dataIndex: "user_details",
    align: "center",
    width: "20%",
    render: (_, { user_details }) => (
      <>
        <p>{user_details?.username > 0 ? user_details?.username : "-"}</p>
      </>
    ),
  },
  {
    title: "From",
    dataIndex: "ride_details",
    align: "center",
    width: "20%",
    render: (_, { ride_details }) => (
      <>
        <p>{ride_details?.from_address}</p>
      </>
    ),
  },

  {
    title: "To",
    dataIndex: "ride_details",
    align: "center",
    width: "20%",
    render: (_, { ride_details }) => (
      <>
        <p>{ride_details?.to_address}</p>
      </>
    ),
  },
  {
    title: "Transport Mode",
    dataIndex: "transport_details",
    align: "center",
    width: "10%",
    render: (_, { transport_details }) => (
      <>
        <p>{transport_details?.transport_type}</p>
      </>
    ),
  },
  {
    title: "Fare",
    dataIndex: "ride_details",
    align: "center",
    width: "20%",
    render: (_, { ride_details }) => (
      <>
        <div className="w-full h-full flex flex-row items-center justify-center">
          <p>{ride_details?.price}</p>
        </div>
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "ride_details",
    align: "center",
    width: "20%",
    render: (_, { ride_details }) => (
      <>
        <TagChip status={ride_details?.status} />
      </>
    ),
  },
];

const fromIcon = new L.Icon({
  iconUrl: "/redflag.png", // Replace with your custom user icon URL
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 30], // Anchor point (adjust as needed)
  popupAnchor: [0, -30], // Popup anchor position
});

const toIcon = new L.Icon({
  iconUrl: "/greenflag.png", // Replace with your custom user icon URL
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 30], // Anchor point (adjust as needed)
  popupAnchor: [0, -30], // Popup anchor position
});

const BikeIcon = new L.Icon({
  iconUrl: `/bike.png`, // Replace with your custom driver icon URL
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 30], // Anchor point
  popupAnchor: [0, -30], // Popup anchor position
});
const CarIcon = new L.Icon({
  iconUrl: `/car.png`, // Replace with your custom driver icon URL
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 30], // Anchor point
  popupAnchor: [0, -30], // Popup anchor position
});
const TukTukIcon = new L.Icon({
  iconUrl: `/tuktuk.png`, // Replace with your custom driver icon URL
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 30], // Anchor point
  popupAnchor: [0, -30], // Popup anchor position
});

function Home({ isCollapsed }) {
  const TABLE_COLUMNS = [
    {
      title: "Sno",
      dataIndex: "driver_details",
      align: "center",
      width: "10%",
      render: (_, { driver_details }, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      title: "Driver Name",
      dataIndex: "driver_details",
      align: "center",
      width: "20%",
      render: (_, { driver_details }) => (
        <>
          <p>
            {driver_details?.username.length > 0
              ? driver_details?.username
              : "-"}
          </p>
        </>
      ),
    },
    {
      title: "From",
      dataIndex: "ride_details",
      align: "center",
      width: "20%",
      render: (_, { ride_details }) => (
        <>
          <p>{ride_details?.from_address}</p>
        </>
      ),
    },

    {
      title: "To",
      dataIndex: "ride_details",
      align: "center",
      width: "20%",
      render: (_, { ride_details }) => (
        <>
          <p>{ride_details?.to_address}</p>
        </>
      ),
    },
    {
      title: "Transport Mode",
      dataIndex: "transport_details",
      align: "center",
      width: "10%",
      render: (_, { transport_details }) => (
        <>
          <p>{transport_details?.transport_type}</p>
        </>
      ),
    },
    {
      title: "Fare",
      dataIndex: "ride_details",
      align: "center",
      width: "20%",
      render: (_, { ride_details }) => (
        <>
          <div className="w-full h-full flex flex-row items-center justify-center">
            <p>{ride_details.price}</p>
          </div>
        </>
      ),
    },
    {
      title: "Track Ride",
      dataIndex: "ride_details",
      align: "center",
      width: "20%",
      render: (_, { ride_details }) => (
        <>
          <button
            className="bg-[#1DC97E] text-[#ffffff] px-4 py-2 rounded-[10px]"
            onClick={() => {
              openActiveRide(_);
              showLoading();
            }}
          >
            Track
          </button>
        </>
      ),
    },
  ];

  const openActiveRide = (data) => {
    setSelectedRide(data);
    let boundlatlng = [];
    locations.map((item, index) => {
      if (item.id === data?.assigned_driver_id) {
        boundlatlng.push({
          lat:item?.location?.lat, 
          long: item?.location?.lon
        })
      }
    })
    boundlatlng.push({lat :data?.from_lat, long: data?.from_lon})
    boundlatlng.push({lat :data?.to_lat, long: data?.to_lon})
    setLatLngBounds(boundlatlng);
  }

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [latlngBounds, setLatLngBounds] = useState([]);
  const [selectedRide, setSelectedRide] = useState(null);

  const showLoading = (item) => {
    console.log("the track item is ", item);

    setOpen(true);
    setLoading(false);

    // Simple loading mock. You should add cleanup logic in real world.
  };

  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [resData, setResData] = useState([]);

  console.log("the thing is", resData);
  const generateData = (data) => {
    console.log("the log is ", data);
    const driverLocations = [];
    const uniqueDrivers = [];
    const seenDrivers = new Set();
    if (
      data[0]?.driver_location !== null &&
      data[0].driver_location.length > 0
    ) {
      data[0]?.driver_location?.forEach((item) => {
        const driverId = Number(item.driver_location.driver_id);
        if (!seenDrivers.has(driverId)) {
          uniqueDrivers.push(item);
          seenDrivers.add(driverId);
        }
      });
      console.log(uniqueDrivers);
      uniqueDrivers?.map((item) => {
        driverLocations.push({
          id: item.driver_location.driver_id,
          location: {
            lat: item.driver_location.driver_lat,
            lon: item.driver_location.driver_lon,
          },
          mode:
            item.vehicle_type === 1
              ? "bike"
              : item.vehicle_type === 2
              ? "auto"
              : "car",
        });
      });
    }

    console.log("locations", driverLocations);
    setLocations(driverLocations);
    setResData(data);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    padding: "0px",
    boxShadow: 24,
    p: 4,
  };

  console.log("the selected", selectedRide);

  const getDataOnLoop = async () => {
    setInterval(async () => {
      const res = await apiService.get(ROUTES.DASH_API);
      // let res = {
      //   data: dashboard,
      // };
      // console.log(res);
      if (true) {
        generateData(res.data.data);
      }
    }, 5000);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getDataOnLoop();
  }, []);

  return (
    <div
      className={`flex  h-[100vh] flex-col w-full overflow-auto relative ${
        isCollapsed ? "w-[95vw]" : "w-[75vw]"
      }`}
    >
      <div className="flex flex-col h-full w-full px-4">
        <div className="text-lg text-white border-b-[1px]">
          <p className="text-[#ffffff] text-3xl py-4">Admin Panel Overview</p>
        </div>
        <div className="flex flex-row w-full text-[#ffffff] gap-4 ">
          <div className="basis-[25%] p-4 flex flex-row justify-between items-center h-full border-[6px] rounded-[10px]">
            <div className="flex flex-col">
              <p className="text-5xl">{`${
                resData[0]?.active_rides === undefined
                  ? "0/0"
                  : `${resData[0]?.active_drivers}` +
                    `/` +
                    `${resData[0]?.total_drivers}`
              }`}</p>
              <p>Active Drivers</p>
            </div>
            <div className="">
              <img src={DriverIcon} alt="" />
            </div>
          </div>
          <div className="basis-[25%] p-4 flex flex-row justify-between items-center h-full border-[6px] rounded-[10px]">
            <div className="flex flex-col">
              <p className="text-5xl">{`${
                resData[0]?.active_rides == null
                  ? `0`
                  : resData[0]?.active_rides.length
              }`}</p>
              <p>Active Rides</p>
            </div>
            <div className="">
              <FaCar style={{ fontSize: "2rem" }} />
            </div>
          </div>
          <div className="basis-[25%] p-4 flex flex-row justify-between items-center h-full border-[6px] rounded-[10px]">
            <div className="flex flex-col">
              <p className="text-5xl">{`${
                resData[0]?.completed_trips === undefined
                  ? "0"
                  : resData[0]?.completed_trips
              }`}</p>
              <p>Rides Completed</p>
            </div>
            <div className="">
              <FaRegCheckCircle style={{ fontSize: "2rem" }} />
            </div>
          </div>
          <div className="basis-[25%] p-4 flex flex-row justify-between items-center h-full border-[6px] rounded-[10px]">
            <div className="flex flex-col">
              <p className="text-5xl">{`${
                resData[0]?.total_earning === undefined
                  ? "0"
                  : resData[0]?.total_earning
              }`}</p>
              <p>Total Earnings</p>
            </div>
            <div className="">
              <p className="text-[1.5rem] font-bold">TZS</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="">
            <p className="text-[#ffffff] text-3xl py-4">
              Live Drivers and Users:-
            </p>
          </div>
          <div className="w-full flex-1 my-2">
            <MapContainer
              key={"main"}
              center={[18.9293, 80.2333]} // Initial center of the map
              zoom={6}
              scrollWheelZoom={true}
              style={{ height: "50vh", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {locations.map((item, index) => (
                <>
                  {/* User Marker */}

                  {/* Driver Marker */}
                  <Marker
                    key={`driver-${index}`}
                    position={[item?.location?.lat, item?.location?.lon]}
                    icon={
                      item?.mode === "bike"
                        ? BikeIcon
                        : item?.mode === "auto"
                        ? TukTukIcon
                        : CarIcon
                    }
                  >
                    <Popup>
                      Driver located at {item?.location?.lat},{" "}
                      {item?.location?.lon}
                    </Popup>
                  </Marker>
                </>
              ))}
              <AllActiveFitBounds locations={locations} />
            </MapContainer>
          </div>
          <div className="flex flex-col w-[100%] rounded-md">
            <p className="text-[#ffffff] text-3xl py-4">Active Rides</p>
            {isLoading ? (
              <Spin />
            ) : (
              <Table
                className="font-[inter] w-full"
                rowClassName={(record, index) =>
                  index % 2 === 0
                    ? "table-row-light highlight-bottom-border"
                    : "table-row-dark highlight-top-border"
                }
                locale={{
                  emptyText: (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        color: "white",
                      }}
                    >
                      No Data Available
                    </div>
                  ),
                }}
                columns={TABLE_COLUMNS}
                bordered={true}
                dataSource={resData[0]?.active_rides}
                //   onChange={handleChange}
                pagination={false}
              />
            )}
          </div>
          <div className="flex flex-col w-[100%]   rounded-md">
            <p className="text-[#ffffff] text-3xl py-4">Recent Rides</p>
            {isLoading ? (
              <Spin />
            ) : (
              <Table
                className="font-[inter] w-full"
                rowClassName={(record, index) =>
                  index % 2 === 0
                    ? "table-row-light highlight-bottom-border"
                    : "table-row-dark highlight-top-border"
                }
                locale={{
                  emptyText: (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        color: "white",
                      }}
                    >
                      No Data Available
                    </div>
                  ),
                }}
                columns={recentRidesTable}
                bordered={true}
                dataSource={resData[0]?.recent_rides}
                //   onChange={handleChange}
                pagination={false}
              />
            )}
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="p-4 single_active_ride_map">
            <div className="flex flex-col justify-center items-center bg-[#000029] p-2 rounded-[10px]">
              <p className="text-[#ffffff] text-lg font-[500]">Active Rides</p>
              <MapContainer
                key={"modal"}
                center={[18.9293, 80.2333]} // Initial center of the map
                zoom={6}
                scrollWheelZoom={true}
                style={{ height: "500px", width: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {locations.map((item, index) => {
                  if (item.id === selectedRide?.assigned_driver_id) {
                    return (
                      <Marker
                        key={`active_ride`}
                        position={[item?.location?.lat, item?.location?.lon]}
                        icon={
                          item?.mode === "bike"
                            ? BikeIcon
                            : item?.mode === "auto"
                            ? TukTukIcon
                            : CarIcon
                        }
                      >
                        <Popup>
                          Driver located at {item?.location?.lat},{" "}
                          {item?.location?.lon}
                        </Popup>
                      </Marker>
                    );
                  }
                  return null; // Return null if condition is not met
                })}
                <Marker
                  key={`active_ride`}
                  position={[selectedRide?.from_lat, selectedRide?.from_lon]}
                  icon={fromIcon}
                >
                  <Popup>
                    Pickup Location at {selectedRide?.from_lat},{" "}
                    {selectedRide?.from_lon}
                  </Popup>
                </Marker>
                <Marker
                  key={`active_ride`}
                  position={[selectedRide?.to_lat, selectedRide?.to_lon]}
                  icon={toIcon}
                >
                  <Popup>
                    Driver located at {selectedRide?.to_lat},{" "}
                    {selectedRide?.to_lon}
                  </Popup>
                </Marker>
                <FitBounds locations={latlngBounds} />
              </MapContainer>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Home;
